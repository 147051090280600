import features from "./__meowcms__/features.json";
import gettingStarted from "./__meowcms__/getting-started.json";
import home from "./__meowcms__/home.json";
import imprint from "./__meowcms__/imprint.json";
import layout from "./__meowcms__/layout.json";
import privacyPolicy from "./__meowcms__/privacy-policy.json";
import singup from "./__meowcms__/signup.json";
import appLayout from "./app/__meowcms__/layout.json";
import loginCode from "./app/__meowcms__/login-code.json";
import login from "./app/__meowcms__/login.json";
import allBlogPosts from "./posts/__meowcms__/all.json";

export const LayoutContent = layout;
export const FeaturesContent = features;
export const HomeContent = home;
export const AllBlogPostsContent = allBlogPosts;
export const ImprintContent = imprint;
export const SignupContent = singup;
export const PrivacyPolicyContent = privacyPolicy;
export const LoginContent = login;
export const GettingStartedContent = gettingStarted;
export const LoginCodeContent = loginCode;
export const AppLayout = appLayout;
